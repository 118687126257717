import PaginationData from "./../../models/general/PaginationData";
import CardPlan from "./CardPlan";
import CardPlansFilter from "./CardPlansFilter";

export default class CardPlans {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.status = 0;
    this.msg = "";
    this.pagination = new PaginationData();
    this.cardPlansData = [];
    this.cardPlan = new CardPlan();
    this.filterData = new CardPlansFilter();
  }
  fillData(data) {
    this.status = data.status;
    this.msg = data.msg;
    this.pagination.fillData(data.pagination);
    this.cardPlansData = data.cardCreationPlansData;
  }
}
