<template>
  <b-modal id="CardPlanInfo" scrollable size="lg" centered hide-footer>
    <template #modal-title>
      <h3>
        <img src="@/assets/images/cardPlans.svg" class="icon-lg" />
        {{ $t("CardPlans.data") }}
      </h3>
    </template>
    <div class="">
      <div class="my-card">
        <div class="row">
          <DataLabelGroup
            :className="'col-lg-12'"
            :value="cardPlan.cardCreationPlanNameAr"
            :title="$t('CardPlans.nameAr')"
            :imgName="'cardPlans.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-12'"
            :value="cardPlan.cardCreationPlanNameEn"
            :title="$t('CardPlans.nameEn')"
            :imgName="'cardPlans.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="cardPlan.cardSymbol"
            :title="$t('CardPlans.Symbol')"
            :imgName="'symbol.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="cardPlan.cardSerialNumberCount"
            :title="$t('CardPlans.SerialNumberCount')"
            :imgName="'number.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="cardPlan.cardStartNumber"
            :title="$t('CardPlans.StartNumber')"
            :imgName="'number.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="cardPlan.cardEndNumber"
            :title="$t('CardPlans.EndNumber')"
            :imgName="'number.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-6'"
            :value="cardPlan.cardCreationPlanDescriptionAr"
            :title="$t('CardPlans.descriptionAr')"
            :imgName="'description.svg'"
          />
          <DataLabelGroup
            :className="'col-lg-6'"
            :value="cardPlan.cardCreationPlanDescriptionEn"
            :title="$t('CardPlans.descriptionEn')"
            :imgName="'description.svg'"
          />

          <DataLabelGroup
            :className="'col-lg-12'"
            :value="cardPlan.cardCreationPlanNotes"
            :title="$t('notes')"
            :imgName="'notes.svg'"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DataLabelGroup from "./../../components/general/DataLabelGroup.vue";
import { timeToLang } from "./../../utils/functions";

export default {
  components: {
    DataLabelGroup,
  },
  data() {
    return {
      language: localStorage.getItem("userLanguage") || "ar",
    };
  },
  props: ["cardPlan"],
  methods: {
    timeToLang(time) {
      return timeToLang(time, this.language);
    },
  },
};
</script>

<style scoped lang="scss"></style>
