<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <div v-if="cardPlans.cardPlansData">
      <div class="row">
        <CardPlanCard
          v-for="(cardPlan, index) in cardPlans.cardPlansData"
          :key="index"
          :defaultImg="cardPlans.cardPlan.defaultImg"
          v-on:setCardPlanData="cardPlans.cardPlan.fillData($event)"
          :cardPlan="cardPlan"
        />
      </div>

      <ActionsData :actionsData="cardPlans.cardPlan" />
      <CardPlanInfo :cardPlan="cardPlans.cardPlan" />
      <CardPlanDelete
        :cardPlan="cardPlans.cardPlan"
        v-on:refresh="getAllCardPlans()"
      />

      <Pagination
        v-if="!isLoading"
        :paginationData="cardPlans.pagination"
        v-on:changePage="changePage"
      />
    </div>

    <CardPlanFloatBtns
      :theFilterData="cardPlans.filterData"
      v-on:search="search($event)"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import createToastMixin from "./../../utils/create-toast-mixin";
import PreLoader from "./../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../components/general/ExceptionWithImg.vue";
import { STATUS } from "./../../utils/constants";
import ActionsData from "./../../components/general/ActionsData.vue";
import CardPlanCard from "./../../components/cardPlans/CardPlanCard.vue";
import CardPlanInfo from "./../../components/cardPlans/CardPlanInfo.vue";
import CardPlanDelete from "./../../components/cardPlans/CardPlanDelete.vue";
import CardPlanFloatBtns from "./../../components/cardPlans/CardPlanFloatBtns.vue";
import Pagination from "./../../components/general/Pagination.vue";
import CardPlans from "./../../models/cardPlans/CardPlans";

export default {
  mixins: [createToastMixin],
  components: {
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    CardPlanCard,
    CardPlanInfo,
    CardPlanDelete,
    CardPlanFloatBtns,
    Pagination,
  },
  computed: {
    ...mapGetters(["userAuthorizeToken"]),
  },
  data() {
    return {
      isLoading: false,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      cardPlans: new CardPlans(),
    };
  },
  methods: {
    changePage(page) {
      this.cardPlans.pagination.selfPage = page;
      this.getAllCardPlans();
    },
    search(data) {
      this.cardPlans.filterData.fillData(data);
      this.cardPlans.cardPlansData = [];
      this.getAllCardPlans();
    },
    async getAllCardPlans() {
      this.isLoading = true;
      try {
        const response = await this.cardPlans.cardPlan.getAllCardPlans(
          this.language,
          this.userAuthorizeToken,
          this.cardPlans.pagination,
          this.cardPlans.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
          this.cardPlans.fillData(response.data);
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.cardPlans.cardPlansData = null;
          this.exceptionMsg = response.data.msg;
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.cardPlans.cardPlansData = null;
          this.$store.dispatch("logoutUser");
        } else {
          this.cardPlans.cardPlansData = null;
          this.exceptionMsg = response.data.msg;
        }
      } catch (error) {
        this.cardPlans.cardPlansData = null;
        this.exceptionMsg = this.$t("errorCatch");
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllCardPlans();
  },
};
</script>
